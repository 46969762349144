import React from "react";
/* import Carousel from "react-bootstrap/Carousel"; */
import ProgressiveImage from "../../components/ProgressiveImage";

function checkJPG(file_name: string) {
  return !file_name.search(/.*.jpg/i);
}

interface GalleryPageProps {}

interface GalleryPageState {
  figure_list: string[];
  render: boolean;
}

class GalleryPage extends React.Component<GalleryPageProps, GalleryPageState> {
  constructor(props: GalleryPageProps) {
    super(props);
    this.state = {
      render: false,
      figure_list: [],
    };
  }

  async componentDidMount() {
    fetch(process.env.PUBLIC_URL + "/assets/gallery/gallery.txt")
      .then((response) => response.text())
      .then((data) => {
        const figure_list = data.split("\n").filter(checkJPG);
        this.setState({ figure_list: figure_list, render: true });
      });
  }

  render() {
    return (
      <div>
        <h2>Having fun with a 35mm lens on an Nikon FE</h2>
        <div>
          {this.state.figure_list.map((figure) => (
            <GalleryPhoto key={figure} name={figure} />
          ))}
        </div>
      </div>
    );
  }
}

export default GalleryPage;

interface GalleryPhotoProps {
  name: string;
}

function GalleryPhoto(props: GalleryPhotoProps) {
  /* console.log("running"); */
  return (
    <ProgressiveImage
      src={process.env.PUBLIC_URL + "/assets/gallery/" + props.name}
      placeholder={
        process.env.PUBLIC_URL + "/assets/small_gallery/" + props.name
      }
      alt={props.name}
    />
  );
}
