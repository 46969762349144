import React from "react";

class BlogsContentPage extends React.Component {
  render() {
    return (
      <div>
        <p>🏗 Under construction 🏗</p>
      </div>
    );
  }
}

export default BlogsContentPage;
