import "./App.css";

import React from "react";
import { Routes, Route } from "react-router-dom";

import WebsiteHeader from "./utils/WebsiteHeader";
import HomePage from "./pages/home/HomePage";
import BlogsContentPage from "./pages/blogs/BlogsContentPage";
import ExperiencePage from "./pages/experience/ExperiencePage";
import GalleryPage from "./pages/gallery/GalleryPage";

class App extends React.Component {
  render() {
    return (
      <div>
        <WebsiteHeader
          name="life, the code and everything"
          routes={[
            { name: "Home", route: "/" },
            { name: "Blogs", route: "/blogs" },
            { name: "Experience", route: "/experience" },
            { name: "Gallery", route: "/gallery" },
          ]}
        />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/blogs" element={<BlogsContentPage />} />
          <Route path="/experience" element={<ExperiencePage />} />
          <Route path="/gallery" element={<GalleryPage />} />
        </Routes>
      </div>
    );
  }
}
export default App;
