import React from "react";
import { Link } from "react-router-dom";

export interface NavigationRoute {
  name: string;
  route: string;
}

interface NavigationBarProps {
  routes: NavigationRoute[];
}
class NavigationBar extends React.Component<NavigationBarProps> {
  constructor(props: NavigationBarProps) {
    super(props);
  }

  render() {
    return (
      <div className="nav">
        <ul>
          {this.props.routes.map((route) => (
            <NavigationLink key={route.name} route={route} />
          ))}
        </ul>
      </div>
    );
  }
}

interface NavigationLinkProps {
  route: NavigationRoute;
}

function NavigationLink(props: NavigationLinkProps) {
  return (
    <li>
      <Link to={props.route.route}>{props.route.name}</Link>
    </li>
  );
}

export default NavigationBar;
