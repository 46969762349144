import React from "react";
import "./experience.css";

class ExperiencePage extends React.Component {
  render() {
    return (
      <div>
        <p>🏗 Under construction 🏗</p>
        {/* <div className="layoutManager">
            <h2> Employment History </h2>
            <hr />
            <div>
            <h3>Software Engineer, DreamsAI Limited</h3>
            <h4>September 2019 - April 20222</h4>
            </div>
            <div>
            <h3> Geospatial Engineer, MapStand Limited </h3>
            </div>
            <div>
            <h3>Geophysicist, Geophysical Data Systems Limited </h3>
            </div>
            <div>
            <h3> Junior Researcher, Royal Holloway University of London</h3>
            </div>
            </div> */}
      </div>
    );
  }
}

export default ExperiencePage;
