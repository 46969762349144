import React from "react";

import NavigationBar, { NavigationRoute } from "./NavigationBar";

interface WebsiteHeaderProps {
  name: string;
  routes: NavigationRoute[];
}

interface WebsiteHeaderState {}

class WebsiteHeader extends React.Component<
  WebsiteHeaderProps,
  WebsiteHeaderState
> {
  constructor(props: WebsiteHeaderProps) {
    super(props);
  }
  render() {
    return (
      <header>
        <h1> {this.props.name} </h1>
        <h2> By Ivan Antonov </h2>
        <NavigationBar routes={this.props.routes} />
      </header>
    );
  }
}

export default WebsiteHeader;
