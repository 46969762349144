import React from "react";
import { Link } from "react-router-dom";

class HomePage extends React.Component {
  render() {
    return (
      <div>
        <div className="layoutManager">
          <div className="imgContent">
            <img
              src={window.location.origin + "/assets/home_profile.jpg"}
              alt=""
              width="100%"
            ></img>
          </div>
          <h3> About Me </h3>
          <p>
            I am a Geology/ Geophysics PhD student that has a passion in
            software development. This website is a storage of my thoughts and
            ideas of my two passions.
          </p>
          <p>
            The thesis title of my PhD is "Geometry, kinematics and mechanics of
            inversion in the Huincul High, Neuquén Basin– economic implications
            of inversion systems". This entails seismic interpretation and
            structural analysis/ modelling. The research specifically emphasises
            the effect of mechanical stratigraphy on the stress/ strain
            distribution and conseuquently the basin evolution.
          </p>
          <p>
            In programming I am an avid advocate of LISP (Emacs) and Rust, but
            do know other languages e.g. Python, Typescript, C++. I am quite
            interested in data paradigms, and recently have been looking into
            infrastructure/ Devops e.g. Kubernetes and Terraform.
          </p>
          <p>
            In my spare time I love to go hiking, and just a while ago acquired
            a Nikon FE film camera that have produced some stunning photos (if I
            do say so myself, check <Link to="/gallery">Gallery</Link>). I have
            played ice hockey from a young age, eventually representing Great
            Britain on the international stage. Apart from that I like reading
            books on Russian Literature on Tuesdays and Stock trading on
            Sundays.
          </p>
          <p>
            Open to new exciting experiences and looking forward to progressing
            both as a geologist and a programmer!
          </p>
          {/* <p className="homeInfo">
              <b>*Programming Languages*:</b> Python, LISP, C++, Rust &
              Typescript (Node/ React)
              </p> */}
        </div>
      </div>
    );
  }
}

/* class Clock extends React.Component {
 *   constructor(props) {
 *     super(props);
 *     this.state = { date: new Date() };
 *   }
 *   componentDidMount() {
 *     this.timerID = setInterval(() => this.tick(), 1000);
 *   }
 *
 *   componentWillUnmount() {
 *     clearInterval(this.timerID);
 *   }
 *
 *   tick() {
 *     this.setState({
 *       date: new Date(),
 *     });
 *   }
 *
 *   render() {
 *     return <h2> {this.state.date.toLocaleTimeString()} </h2>;
 *   }
 * }
 *
 * class ToggleButton extends React.Component {
 *   constructor(props) {
 *     super(props);
 *     this.state = {
 *       isToggleOn: true,
 *     };
 *   }
 *
 *   handleClick(e) {
 *     this.setState((state) => ({
 *       isToggleOn: !state.isToggleOn,
 *     }));
 *   }
 *
 *   render() {
 *     return (
 *       <button onClick={(e) => this.handleClick(e)}>
 *         {this.state.isToggleOn ? "On" : "Off"}
 *       </button>
 *     );
 *   }
 * } */

export default HomePage;
